import React from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ThemeWrapper from './ThemeWrapper';

const WrapperIndex: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeWrapper>{children}</ThemeWrapper>
      </LocalizationProvider>
    </>
  );
};

export default WrapperIndex;
