export type GetOrderInfoResponse = {
  amount?: number;
  status?: OrderStatus;
  paymentOptions?: PaymentMethodDto[];
  preferredPaymentApps?: string[];
  walletBalance?: number;
  requestedBy?: string;
};

type PaymentMethodDto = {
  paymentMethod?: PaymentMethod;
  icon?: string;
  name?: string;
  description?: string;
  acceptCards?: AcceptCardsDto[];
  paymentGateway?: PaymentGateway;
  pgGatewayAttributes?: Record<string, unknown>;
  bankList?: BankDto[];
  vpaList?: string[];
  isCollectFlowEnabled?: boolean;
  isIntentFlowEnabled?: boolean;
};

export enum PaymentMethod {
  WALLET = 'WALLET',
  CREDIT_CARD = 'CREDIT_CARD',
  DEBIT_CARD = 'DEBIT_CARD',
  UPI = 'UPI',
  NET_BANKING = 'NET_BANKING',
}

export enum PaymentGateway {
  RAZORPAY,
  WALLET,
  UNSUPPORTED,
}

export type AcceptCardsDto = {
  cardName?: string;
  cardImageUrl?: string;
};

export type BankDto = {
  bankName?: string;
  pgBankCode?: string;
  icon?: string;
};

export enum OrderStatus {
  INIT = 'INIT',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}
