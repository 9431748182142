import React from 'react';

const ErrorPage = () => {
  return (
    <div
      className={
        'flex w-full h-full justify-center items-center text-red-800 font-semibold'
      }
    >
      Error Page Not Found
    </div>
  );
};

export default ErrorPage;
