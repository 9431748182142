import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const PathParser = () => {
  let { token } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    navigate(`/?token=${token}`);
  }, [token]);

  return <div>Welcome To DriveMo</div>;
};

export default PathParser;
