import React from 'react';

export type ButtonType = 'pickup' | 'dropoff';

type ToggleButtonProps = {
  active: ButtonType;
  setActive: (buttonType: ButtonType) => void;
};
const ToggleButton: React.FC<ToggleButtonProps> = ({ active, setActive }) => {
  return (
    <div className="flex justify-left items-center gap-2 rounded-lg p-1 bg-gray-100 w-fit self-center">
      <button
        className={`rounded-lg px-4 py-2 ${
          active === 'pickup'
            ? 'bg-primary text-white'
            : 'bg-transparent text-gray-800'
        }`}
        onClick={() => setActive('pickup')}
      >
        Pickup Photos
      </button>
      <button
        className={`rounded-lg px-4 py-2 ${
          active === 'dropoff'
            ? 'bg-primary text-white'
            : 'bg-transparent text-gray-800'
        }`}
        onClick={() => setActive('dropoff')}
      >
        Drop Photos
      </button>
    </div>
  );
};

export default ToggleButton;
