import dayjs from 'dayjs';

export function convertTime(timeString?: string): string {
  if (!dayjs(`1970-01-01T${timeString}`).isValid()) {
    return '';
  }
  return dayjs(`1970-01-01T${timeString}`).format('h:mm A');
}

export function convertTimePlusThirty(timeString: string): string {
  return dayjs(`1970-01-01T${timeString}`).add(30, 'minute').format('h:mm A');
}

export function convertToDateObject(
  dateString: string,
  timeString: string
): Date {
  return dayjs(`${dateString}T${timeString}`).toDate();
}

export function convertDateToDateObject(dateString: string): Date {
  return dayjs(`${dateString}T00:00:00`).toDate();
}

export function formatDateToString(date?: Date): string {
  if (dayjs(date).isValid()) {
    return dayjs(date).format('DD MMMM YYYY h:mm A');
  } else {
    return '';
  }
}

export function formatDateToTimeString(date?: Date): string {
  if (dayjs(date).isValid()) {
    return dayjs(date).format('h:mm A');
  } else {
    return '';
  }
}

export function formatDateToDateString(date?: Date): string {
  if (dayjs(date).isValid()) {
    return dayjs(date).format('DD MMMM YYYY');
  } else {
    return '';
  }
}
