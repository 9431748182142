import { createTheme, ThemeOptions } from '@mui/material/styles';
import { COLORS } from '../constants/colors';

export const theme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY,
    },
    secondary: {
      main: COLORS.SECONDARY,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: 'small',
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: COLORS.PRIMARY,
          textTransform: 'none',
          borderRadius: '0.8rem',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            padding: '0',
            backgroundColor: 'rgba(245, 245, 245, 0.4)',
          },
          '& .MuiOutlinedInput-root': {
            border: '0.05rem solid rgba(170, 175, 221, 0.42)',
            borderRadius: '0.375rem',
            backgroundColor: 'var(--bg-gray-light)',
            '&.Mui-focused': {
              outline: 'none',
            },
          },
          '& .MuiInputBase-input': {
            height: '100%',
            width: '100%',
            color: 'black', // Add this line
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiSelect-select': {
            padding: '0.5rem 0.8rem',
            backgroundColor: 'rgba(245, 245, 245, 0.4)',
          },
          '& .MuiSelect-outlined': {
            borderRadius: '0.375rem',
            border: '0.1rem solid rgba(170, 175, 221, 0.42)',
            backgroundColor: 'var(--bg-gray-light)',
            '&.Mui-focused': {
              outline: 'none',
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '0.9rem',
        },
      },
    },
  },
});
