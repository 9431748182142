import React from 'react';
import ViewPhotoModal from './ViewPhotoModal';
import ViewBookingOtpModal from './ViewBookingOtpModal';
import GlobalLoaderModal from './GlobalLoaderModal';
import SuccessModal from './SuccessModal';

const ModalWrapper = () => {
  return (
    <div>
      <GlobalLoaderModal />
      <SuccessModal />
      <ViewPhotoModal />
      <ViewBookingOtpModal />
    </div>
  );
};

export default ModalWrapper;
