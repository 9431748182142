import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  AddressAutoCompleteResponse,
  editCustomerLocation,
  fetchAddressesForAutoComplete,
  fetchCompleteAddress,
} from '../services/booking';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  CustomerLocationResponse,
  CustomerLocationResponseSchema,
  defaultCustomerLocationResponse,
} from '../dto/response/booking-prefill-data-response';
import MapComponent from '../components/shared/MapComponent';
import CustomTextField from '../components/shared/CustomTextField';
import {
  Autocomplete,
  Button,
  CircularProgress,
  SwipeableDrawer,
  TextField,
} from '@mui/material';
import { debounce, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { CustomSelectOptions } from '../components/shared/CustomSelect';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import toast from 'react-hot-toast';
import { setModalState } from '../store/modal/modalSlice';

type Coords = {
  lat: number;
  lng: number;
};

const EditLocation = () => {
  const [geoLocationCoordinates, setGeoLocationCoordinates] =
    useState<Coords | null>(null);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const textFieldRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();

  const { open: isOpen, payload } = useAppSelector(
    (state) => state.modal['share.location']
  );

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);
  const [placeId, setPlaceId] = useState<string>('');
  const [options, setOptions] = useState<CustomSelectOptions[]>([]);
  const [text, setText] = useState<string>('');
  const [autocompleteOpen, setAutocompleteOpen] = useState<boolean>(false);
  const [sessionToken, setSessionToken] = useState(uuidv4());
  const debouncedRefetch = debounce(async () => await refetch(), 1000);

  const { mutateAsync: editLocationMutation } =
    useMutation(editCustomerLocation);

  const { remove, refetch: refetchCompleteAddress } =
    useQuery<AddressAutoCompleteResponse>(
      `complete/address`,
      () => fetchCompleteAddress(placeId, token ?? '', sessionToken),
      {
        enabled: false,
        onSuccess: (completeAddress: AddressAutoCompleteResponse) => {
          setGeoLocationCoordinates({
            lat: completeAddress.latitude,
            lng: completeAddress.longitude,
          });
        },
      }
    );

  const { refetch, isLoading } = useQuery<AddressAutoCompleteResponse[]>(
    `autocomplete`,
    () => fetchAddressesForAutoComplete(text, token ?? '', sessionToken),
    {
      onSuccess: (autocomplete: AddressAutoCompleteResponse[]) => {
        const optionList: CustomSelectOptions[] = autocomplete.map(
          (entry: AddressAutoCompleteResponse) => {
            return {
              value: entry.placeId,
              label: entry.address,
            };
          }
        );
        setOptions((prevState) => optionList);
      },
      enabled: false,
    }
  );

  useEffect(() => {
    if (!isOpen && payload?.path === 'home') {
      navigate(`/${token}`);
    }
  }, [isOpen, payload]);

  useEffect(() => {
    if (geoLocationCoordinates) {
      setValue('customerLatitude', geoLocationCoordinates.lat);
      setValue('customerLongitude', geoLocationCoordinates.lng);
    }
  }, [geoLocationCoordinates]);

  const { control, handleSubmit, trigger, formState, watch, setValue, reset } =
    useForm<CustomerLocationResponse>({
      defaultValues: defaultCustomerLocationResponse,
      resolver: zodResolver(CustomerLocationResponseSchema),
    });

  const onSubmit: SubmitHandler<CustomerLocationResponse> = async (
    data: any
  ) => {
    console.log(data);
    data.token = token;
    toggleDrawer(false);
    await editLocationMutation(data, {
      onSuccess: () => {
        dispatch(
          setModalState({ modal: 'share.location', state: { open: true } })
        );
      },
    });
  };

  useEffect(() => {
    if (formState.errors.customerLatitude && textFieldRef.current) {
      textFieldRef.current.scrollIntoView({ behavior: 'smooth' });
      textFieldRef.current.focus();
    }
  }, [formState.errors.customerLatitude]);

  useEffect(() => {
    fetchLocation();
  }, []);

  useEffect(() => {
    if (isEmpty(placeId)) return;

    refetchCompleteAddress();
  }, [placeId]);

  function fetchLocation() {
    try {
      if (navigator.geolocation) {
        navigator.permissions
          .query({ name: 'geolocation' })
          .then((permissionStatus) => {
            if (permissionStatus.state === 'denied') {
              toast.error('Please allow location access.');
              setError(true);
              setLoading(false);
            } else {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  setGeoLocationCoordinates({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  });
                  setLoading(false);
                },
                (error) => {
                  console.error(
                    'Error Code = ' + error.code + ' - ' + error.message
                  );
                  setError(true);
                  setLoading(false);
                }
              );
            }
          });
      } else {
        console.error('Geolocation is not supported by this browser.');
        setError(true);
        toast.error('Location Input is not supported by this browser.');
        setLoading(false);
      }
    } catch (error) {
      console.error('Geolocation is not supported by this browser.');
      setError(true);
      setLoading(false);
    }
  }

  const handleChange = async (event: any) => {
    const val = event.target.value;
    if (isEmpty(val)) {
      return;
    }
    setText(event.target.value);
    if (!event.target.value) {
      setAutocompleteOpen(false);
    }
    await debouncedRefetch();
  };

  const onSelectAddress = (option: CustomSelectOptions) => {
    setOptions([]);
    setPlaceId((prevState) => {
      if (typeof option.value === 'number') {
        return option.value.toString();
      }
      return option.value;
    });
  };

  const customFilterOptions = (
    options: CustomSelectOptions[]
  ): CustomSelectOptions[] => {
    return options;
  };

  const handleLocateMeClick = () => {
    if (error) {
      return;
    }
    remove();
    setLoading(true);
    setError(false);
    fetchLocation();
  };

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleConfirmLocation = async () => {
    // List of form fields to check for errors
    const isValid = await trigger('customerLatitude');

    if (isValid) {
      toggleDrawer(true)();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={
          'flex flex-col h-[75vh] min-h-[60vh] max-h-[80vh] justify-between w-full gap-4 items-center'
        }
      >
        <SwipeableDrawer
          onOpen={toggleDrawer(true)}
          anchor={'bottom'}
          open={open}
          className={'w-full h-fit bg-white'}
          onClose={toggleDrawer(false)}
        >
          <div className={'flex flex-col gap-5 w-full px-4 py-3'}>
            <CustomTextField
              control={control}
              label={'House Number/Flat Number'}
              fieldName={'addressLine1'}
              textFieldType={'text'}
            />
            <CustomTextField
              control={control}
              label={'Street & Locality'}
              fieldName={'addressLine2'}
              textFieldType={'text'}
            />
            <Button className={'w-full h-16'} onClick={handleSubmit(onSubmit)}>
              Save Address Details
            </Button>
          </div>
        </SwipeableDrawer>
        {loading ? (
          <div className={'flex flex-col w-full gap-10 h-full items-center'}>
            <CircularProgress />
            <div className={'text-primary'}>Fetching Current Location...</div>
          </div>
        ) : (
          <MapComponent
            className={
              'h-full flex flex-col items-center justify-between focus:outline-none px-2'
            }
            center={{
              lat: error
                ? geoLocationCoordinates?.lat || 0
                : geoLocationCoordinates
                  ? geoLocationCoordinates.lat
                  : 0,
              lng: error
                ? geoLocationCoordinates?.lng || 0
                : geoLocationCoordinates
                  ? geoLocationCoordinates.lng
                  : 0,
            }}
            setCenter={setGeoLocationCoordinates}
          >
            <Autocomplete
              disableClearable
              className={
                'mx-2 w-full bg-white z-20 h-fit rounded-xl mt-2 shadow-md'
              }
              freeSolo
              options={options}
              open={autocompleteOpen}
              filterOptions={customFilterOptions}
              onOpen={() => setAutocompleteOpen(true)}
              onClose={() => setAutocompleteOpen(false)}
              onChange={(event, value) => {
                if (typeof value !== 'string') {
                  onSelectAddress(value);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={handleChange}
                  className={'h-fit'}
                  label={
                    formState.errors.customerLatitude
                      ? formState.errors.customerLatitude.message
                      : 'Search for area, street name...'
                  }
                  error={!!formState.errors.customerLatitude}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                    endAdornment: (
                      <>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  inputRef={textFieldRef}
                />
              )}
            />
            <button
              className={`mb-2 w-fit flex items-center justify-center gap-1 h-fit py-2 px-3 z-20 bg-white rounded-xl shadow-xl ${error ? 'text-gray-500' : 'text-primary'}`}
              onClick={handleLocateMeClick}
            >
              <span>
                <MyLocationIcon
                  className={`${error ? 'text-gray-500' : 'text-primary'}`}
                />
              </span>
              {error ? 'Location Permission Denied' : 'Use Current Location'}
            </button>
          </MapComponent>
        )}

        <Button className={'w-full h-16'} onClick={handleConfirmLocation}>
          Confirm Your Location
        </Button>
      </div>
    </form>
  );
};

export default EditLocation;
