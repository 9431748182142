import { paymentServiceAxios } from './axios';
import { GetOrderInfoResponse } from '../dto/response/get-payment-info';
import { StartPaymentRequest } from '../dto/request/start-payment-request';
import { StartPaymentResponse } from '../dto/response/start-payment-response';
import { AxiosResponse } from 'axios';
import { OrderStatusResponse } from '../dto/response/order-status-response';
import { CompletePaymentRequest } from '../dto/request/complete-payment-request';
import { GetPaymentDetailsResponse } from '../dto/response/get-payment-details';

export const fetchPaymentInfo = async (
  token: string
): Promise<GetOrderInfoResponse> => {
  console.log('fetchPaymentInfo token', token);

  return paymentServiceAxios
    .get<GetOrderInfoResponse>('/api/v1/payer/payment/info', {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const startPayment = async (
  token: string,
  request: StartPaymentRequest
): Promise<StartPaymentResponse> => {
  return await paymentServiceAxios
    .post<null, AxiosResponse<StartPaymentResponse>, StartPaymentRequest>(
      '/api/v1/payer/payment/start',
      request,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data);
};

export const completePayment = async (
  token: string
): Promise<OrderStatusResponse> => {
  return await paymentServiceAxios
    .post<null, AxiosResponse<OrderStatusResponse>, CompletePaymentRequest>(
      '/api/v1/payer/payment/complete',
      {
        orderId: 1,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data);
};

export const fetchPaymentDetails = async (
  token: string
): Promise<GetPaymentDetailsResponse> => {
  return paymentServiceAxios
    .get<GetPaymentDetailsResponse>('/api/v1/payer/payment/details', {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};
