import React, { useEffect, useState } from 'react';
import {
  AdvancedMarker,
  APIProvider,
  Map,
  Pin,
} from '@vis.gl/react-google-maps';
import { BookingDetailResponse } from '../dto/response/booking-detail-response';
import { BookingSubType } from '../enums/booking-enums';
import { CaptainBookingLocationResponse } from '../dto/response/booking-location-response';
import {
  dropForCustomerOrServiceCentreForMap,
  pickupForCustomerOrServiceCentreForMap,
} from '../utils/booking';

type MarkerProps = {
  lat: number;
  lng: number;
};

export type BookingTrackingProps = {
  prop?: BookingDetailResponse;
  captainLocation?: CaptainBookingLocationResponse;
};

const BookingTracking: React.FC<BookingTrackingProps> = ({
  prop,
  captainLocation,
}) => {
  const [pickUpLocation, setPickUpLocation] = useState<MarkerProps>();
  const [dropOffLocation, setDropOffLocation] = useState<MarkerProps>();

  useEffect(() => {
    if (prop?.bookingReferenceNumber) {
      if (prop.bookingSubType === BookingSubType.PICKUP) {
        setDropOffLocation({
          lat: prop.branchLocation.latitude,
          lng: prop.branchLocation.longitude,
        });

        setPickUpLocation({
          lat: prop.customerLocationResponse.customerLatitude,
          lng: prop.customerLocationResponse.customerLongitude,
        });
      } else {
        setDropOffLocation({
          lat: prop.customerLocationResponse.customerLatitude,
          lng: prop.customerLocationResponse.customerLongitude,
        });
        setPickUpLocation({
          lat: prop.branchLocation.latitude,
          lng: prop.branchLocation.longitude,
        });
      }
    }
  }, [prop, captainLocation]);

  const calculateCenter = () => {
    if (captainLocation) {
      return {
        lat: captainLocation.location.latitude,
        lng: captainLocation.location.longitude,
      };
    }
    if (pickUpLocation && dropOffLocation) {
      return {
        lat: (pickUpLocation.lat + dropOffLocation.lat) / 2,
        lng: (pickUpLocation.lng + dropOffLocation.lng) / 2,
      };
    }
    return {
      lat: prop?.branchLocation.latitude || 0,
      lng: prop?.branchLocation.longitude || 0,
    };
  };

  const center = calculateCenter();
  const zoom = captainLocation ? 14 : 11;

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_KEY || ''} region={'in'}>
      {prop && (
        <Map
          className="w-full h-52 border rounded-xl"
          id={prop.bookingReferenceNumber}
          mapId={'42ac7a87faab51bb'}
          zoom={zoom}
          center={center}
          disableDefaultUI
          gestureHandling={'greedy'}
        >
          <AdvancedMarker
            position={{
              lat: pickUpLocation?.lat || 0,
              lng: pickUpLocation?.lng || 0,
            }}
            draggable={false}
          >
            <Pin
              background={'#b289e8'}
              borderColor={'#660bd9'}
              glyphColor={'#1a0031'}
            >
              <div
                className={
                  'text-xs text-white relative -bottom-10 bg-primary rounded px-2 py-1 w-fit text-center'
                }
              >
                {pickupForCustomerOrServiceCentreForMap(prop.bookingSubType)}
              </div>
            </Pin>
          </AdvancedMarker>

          {captainLocation && (
            <AdvancedMarker
              zIndex={40}
              position={{
                lat: captainLocation.location.latitude,
                lng: captainLocation.location.longitude,
              }}
              draggable={false}
            >
              <img src={'car-pin.png'} width={50} height={50} />
            </AdvancedMarker>
          )}

          <AdvancedMarker
            position={{
              lat: dropOffLocation?.lat || 0,
              lng: dropOffLocation?.lng || 0,
            }}
            draggable={false}
          >
            <Pin
              background={'#fac92a'}
              borderColor={'#ad6f00'}
              glyphColor={'#60d98f'}
            >
              <div
                className={
                  'text-xs text-white relative -bottom-10 bg-yellow-700 rounded px-2 py-1 w-fit text-center'
                }
              >
                {dropForCustomerOrServiceCentreForMap(prop.bookingSubType)}
              </div>
            </Pin>
          </AdvancedMarker>
        </Map>
      )}
    </APIProvider>
  );
};

export default BookingTracking;
