import { BookingProgressStatus } from '../enums/booking-enums';

export function toTitleCase(str: string): string {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function fromBookingProgressStatusToString(
  status: BookingProgressStatus
): string {
  switch (status) {
    case BookingProgressStatus.ARRIVED_AT_DROP:
      return 'Arrived at Drop';
    case BookingProgressStatus.ARRIVED_AT_PICKUP:
      return 'Arrived at Pickup';
    case BookingProgressStatus.CANCELLED:
      return 'Cancelled';
    case BookingProgressStatus.END_TRIP:
      return 'Completed';
    case BookingProgressStatus.DROP_OTP_VERIFIED:
      return 'Drop OTP Verified';
    case BookingProgressStatus.PICKUP_IMAGES_UPLOADED:
      return 'Pickup Images Uploaded';
    case BookingProgressStatus.PICKUP_OTP_VERIFIED:
      return 'Pickup OTP Verified';
    case BookingProgressStatus.ON_THE_WAY_TO_DROP:
      return 'On the way to Drop';
    case BookingProgressStatus.ON_THE_WAY_TO_PICKUP:
      return 'On the way to Pickup';
  }
}
