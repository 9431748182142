// BookingHistory.tsx
import React, { useEffect, useState } from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { BookingProp } from '../dto/BookingProp';
import {
  formatDateToDateString,
  formatDateToTimeString,
} from '../utils/datetime';
import { BookingSubType } from '../enums/booking-enums';
import { BookingCaptainType } from '../dto/response/booking-detail-response';
import { isEmpty } from 'lodash';
import {
  dropForCustomerOrServiceCentre,
  pickupForCustomerOrServiceCentre,
} from '../utils/booking';

interface BookingHistoryProps {
  items: {
    date: string;
    time: string;
    status?: string;
  }[];
}

const BookingTimeline: React.FC<BookingProp> = ({ prop }) => {
  const [timeLine, setTimeLine] = useState<BookingHistoryProps>();

  const [activeStep, setActiveStep] = useState(0);

  function getItemFromStage(status: string, date?: Date) {
    return {
      date: formatDateToDateString(date),
      time: formatDateToTimeString(date),
      status: status,
    };
  }

  useEffect(() => {
    let items = [];
    if (prop) {
      items.push(
        getItemFromStage('On the way to Pickup', prop.captainStartedTripAt)
      );
      items.push(
        getItemFromStage(
          `Reached ${pickupForCustomerOrServiceCentre(prop.bookingSubType)} Location`,
          prop.reachedPickupLocationAt
        )
      );
      {
        prop?.bookingSubType === BookingSubType.PICKUP &&
          prop.bookingCaptainType === BookingCaptainType.BRANCH_CAPTAIN &&
          items.push(
            getItemFromStage('Pickup OTP Verified', prop.pickupOtpVerifiedAt)
          );
      }
      items.push(
        getItemFromStage('Pickup Images Uploaded', prop.pickUpImagesUploadedAt)
      );
      items.push(
        getItemFromStage(
          'On the Way to Drop Location',
          prop.leftPickupLocationAt
        )
      );
      items.push(
        getItemFromStage(
          `Reached ${dropForCustomerOrServiceCentre(prop.bookingSubType)} Location`,
          prop.reachedDropOffLocationAt
        )
      );
      {
        prop?.bookingSubType === BookingSubType.DROPOFF &&
          prop.bookingCaptainType === BookingCaptainType.BRANCH_CAPTAIN &&
          items.push(
            getItemFromStage('Drop OTP Verified', prop.dropOtpVerifiedAt)
          );
      }
      items.push(
        getItemFromStage(
          'Clicked Pictures & Completed Booking',
          prop.captainEndTripAt
        )
      );
    }
    setTimeLine({ items });
  }, [prop]);

  useEffect(() => {
    if (!isEmpty(prop?.captainEndTripAt)) {
      return setActiveStep(7);
    }

    if (
      prop?.bookingSubType === BookingSubType.DROPOFF &&
      !isEmpty(prop?.dropOtpVerifiedAt)
    ) {
      return setActiveStep(6);
    }

    if (!isEmpty(prop?.reachedDropOffLocationAt)) {
      return setActiveStep(5);
    }

    if (!isEmpty(prop?.leftPickupLocationAt)) {
      return setActiveStep(4);
    }

    if (!isEmpty(prop?.pickUpImagesUploadedAt)) {
      return setActiveStep(3);
    }

    if (
      prop?.bookingSubType === BookingSubType.PICKUP &&
      !isEmpty(prop?.pickupOtpVerifiedAt)
    ) {
      return setActiveStep(2);
    }

    if (!isEmpty(prop?.reachedPickupLocationAt)) {
      return setActiveStep(1);
    }

    if (!isEmpty(prop?.captainStartedTripAt)) {
      return setActiveStep(0);
    }
  }, [prop]);

  return (
    <div className={'border border-gray-400 p-4 rounded-xl border-dashed'}>
      <div className={'text-primary uppercase mb-4'}>Booking History</div>
      <Stepper activeStep={activeStep} orientation="vertical">
        {timeLine?.items.map((step, index) => (
          <Step key={step.status}>
            <StepLabel>
              <h2 className={'text-lg'}>{step.status}</h2>
              <div className={'flex flex-col gap-2 justify-end'}>
                <div>
                  <h3 className={'text-gray-400'}>{step.date}</h3>
                  <h3 className={'text-gray-400'}>{step.time}</h3>
                </div>
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default BookingTimeline;
