import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export type UserDataState = {
    userName: string; channelPartnerName: string; permissions: string[]; isPrimary: boolean;
};

export type AuthState = {
    token?: string; userData?: UserDataState;

};

const initialState: AuthState = {
    token: undefined, userData: undefined,

};

export const authSlice = createSlice({
    name: "auth", initialState, reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        }, logout: (state) => {
            state.token = undefined;
        }, setUserData(state, action: PayloadAction<UserDataState>) {
            state.userData = action.payload;
        },

    },
});

export const {setToken, logout, setUserData} = authSlice.actions;

export const getToken = (state: any) => state.auth.token;
export const getUserData = (state: any) => state.auth.userData;

export const getBranchData = (state: any) => state.auth.branchData;
export default authSlice.reducer;
