export const COLORS = Object.freeze({
  PRIMARY: "#5307B4",
  SECONDARY: "#A867FC",
  PRIMARY_BLACK: "#11142D",
  GRAY: {
    LIGHT: "#F4F5F7",
    TEXT: "#676979",
    CONNECTOR_LINE: "#DDDAE4",
    STEPPER_COMPLETED_STAGE: "#efeded",
    STEPPER_PENDING: "#F4F5F7",
  },
});
