import React from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import { Checkbox, TextField } from "@mui/material";

type CustomTextFieldProps = {
  control: Control<any>;
  label: string;
  fieldName: string;
  textFieldType: "text" | "number";
  rules?: RegisterOptions;
  className?: string;
  disabled?: boolean | false;
  children?: React.ReactNode;
  autoFocus?: boolean | false;
  multiline?: boolean | false;
  minRows?: number;
};
const CustomTextField: React.FC<CustomTextFieldProps> = ({
  control,
  label,
  fieldName,
  textFieldType,
  rules,
  disabled,
  className,
  children,
  autoFocus,
  multiline,
  minRows,
}) => {
  return (
    <div className={`flex flex-col w-full text-sm h-fit gap-1 ${className}`}>
      <h3>{label}</h3>
      <Controller
        name={fieldName}
        rules={rules}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            autoFocus={autoFocus}
            fullWidth
            spellCheck
            minRows={minRows}
            multiline={multiline}

            disabled={disabled}
            type={textFieldType}
            error={!!fieldState.error}
            helperText={fieldState.error && fieldState.error.message}
            {...field}
            onChange={(event) => {
              if (textFieldType === 'number') {
                field.onChange(Number(event.target.value));
              } else {
                field.onChange(event.target.value);
              }
            }}
          />
        )}
      />
      {children}
    </div>
  );
};

export default CustomTextField;
