import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import Home from './pages/Home';
import Layout from './components/Header';
import Photos from './pages/Photos';
import store from './store';
import queryClient from './services/react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import WrapperIndex from './wrappers/WrapperIndex';
import ModalWrapper from './modals';
import DigitalInventory from './pages/DigitalInventory';
import Invoices from './pages/Invoices';
import { Toaster } from 'react-hot-toast';
import PathParser from './pages/PathParser';
import RenderRazorpay from './pages/Razorpay';
import PaymentStatus from './pages/PaymentStatus';
import EditLocation from './pages/EditLocation';

function App() {
  return (
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        {process.env.NODE_ENV === 'development' && (
          <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
        )}
        <WrapperIndex>
          <Toaster
            position="bottom-center"
            toastOptions={{
              duration: 4000,
              className: 'toast',
            }}
          />
          <ModalWrapper />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="/edit/location" element={<EditLocation />} />
                <Route path="/photos" element={<Photos />} />
                <Route path="/inventory" element={<DigitalInventory />} />
                <Route path="/invoice" element={<Invoices />} />
                <Route path="/payment/status" element={<PaymentStatus />} />
                <Route path="/:token" element={<PathParser />} />
                <Route path="*" element={<ErrorPage />} />
              </Route>
              <Route path="/payment" element={<RenderRazorpay />} />
            </Routes>
          </BrowserRouter>
        </WrapperIndex>
      </QueryClientProvider>
    </ReduxProvider>
  );
}

export default App;
