import React from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setModalState } from '../store/modal/modalSlice';
import { Modal } from '@mui/material';

const ViewPhotoModal = () => {
  const dispatch = useAppDispatch();

  const { open: isOpen, payload } = useAppSelector(
    (state) => state.modal['view.photo']
  );

  const handleClose = () => {
    dispatch(setModalState({ modal: 'view.photo', state: { open: false } }));
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      classes={{ root: 'flex items-center justify-center' }}
    >
      <div className="relative">
        <img src={payload?.item?.url} className={'w-[90vw]'} />
      </div>
    </Modal>
  );
};

export default ViewPhotoModal;
