import React, { useEffect, useState } from 'react';
import { Button, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { BookingProp } from '../dto/BookingProp';
import { isEmpty } from 'lodash';

const VehicleDetailSection: React.FC<BookingProp> = ({ prop }) => {
  const [hasDigitalInventory, setHasDigitalInventory] =
    useState<boolean>(false);
  const [hasVehicleImages, setHasVehicleImages] = useState<boolean>(false);

  useEffect(() => {
    if (prop) {
      setHasDigitalInventory(
        prop?.digitalInventory !== null &&
          prop?.digitalInventory?.vehicleKmReading !== null
      );
      setHasVehicleImages(
        !isEmpty(prop?.pickupMedia) || !isEmpty(prop?.dropMedia)
      );
    }
  }, [prop]);

  return hasDigitalInventory || hasVehicleImages ? (
    <div className={'flex flex-col gap-2'}>
      <h4 className={'text-primary uppercase'}>Vehicle Details</h4>
      <div className={'flex flex-row gap-2 w-full z-0'}>
        {hasVehicleImages && (
          <Link
            className={'w-full'}
            to="/photos"
            state={{
              prop,
            }}
          >
            <Button variant={'contained'} className={'w-full h-12'}>
              View Photos
            </Button>
          </Link>
        )}
        {hasDigitalInventory && (
          <Link
            className={'w-full'}
            to="/inventory"
            state={{
              prop,
            }}
          >
            <Button
              variant={'contained'}
              className={'w-full rounded-xl h-12'}
              fullWidth
            >
              View Digital Inventory
            </Button>
          </Link>
        )}
      </div>
      <Divider flexItem className={'w-full text-border-500'} />
    </div>
  ) : (
    <></>
  );
};

export default VehicleDetailSection;
