import React from 'react';

type UpdateCardProps = {
  title: string;
  description: string;
};
const UpdateCard: React.FC<UpdateCardProps> = ({ title, description }) => {
  return (
    <div
      className={
        'rounded-xl px-4 py-4 flex flex-col justify-between w-full text-white gap-5 bg-gradient-to-r from-purple-600 to-purple-800'
      }
    >
      <h3 className={'text uppercase'}>{title}</h3>
      <p className={'text-3xl'}>{description}</p>
    </div>
  );
};

export default UpdateCard;
