import React from 'react';

type DetailCardProps = {
  children?: React.ReactNode;
  className?: string;
};
const DetailCard: React.FC<DetailCardProps> = ({ children, className }) => {
  return (
    <div
      className={`rounded-xl border border-zinc-300 py-4 px-3 ${className} flex`}
    >
      {children}
    </div>
  );
};

export default DetailCard;
