import { z } from 'zod';

export type CustomerDetail = {
  id?: number;
  customerName: string;
  mobileNumber: string;
  vehicleNumber: string;
  vehicleBrand: string;
  vehicleModel: string;
  vehicleVariant: string;
};

export type CustomerLocationResponse = {
  token?: string;
  id?: number;
  customerLatitude: number;
  customerLongitude: number;
  addressLine1: string;
  addressLine2: string;
};

export const nonEmptyString = (message: string) => {
  return z.string().min(1, { message: message });
};

export const CustomerLocationResponseSchema = z.object({
  customerLatitude: z
    .number()
    .min(1, { message: 'Search for area, street name...' }),
  customerLongitude: z
    .number()
    .min(1, { message: 'Search for area, street name...' }),
  addressLine1: nonEmptyString('House Number/Flat Number is Mandatory'),
  addressLine2: nonEmptyString('Street & Locality is Mandatory'),
});

export const defaultCustomerLocationResponse: CustomerLocationResponse = {
  customerLatitude: 0,
  customerLongitude: 0,
  addressLine1: '',
  addressLine2: '',
};

export type BookingPrefillDataResponse = {
  customerDetails: CustomerDetail[];
  locationSuggestions: CustomerLocationResponse[];
};
