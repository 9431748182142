import React, { useEffect, useState } from 'react';
import { BookingProp } from '../dto/BookingProp';
import { InvoiceType } from '../enums/booking-enums';
import { Button, Divider } from '@mui/material';
import { InvoiceResponse } from '../dto/response/booking-detail-response';
import { Link, useSearchParams } from 'react-router-dom';
import { COLORS } from '../constants/colors';

const InvoiceView: React.FC<BookingProp> = ({ prop }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [paymentInvoice, setPaymentInvoice] = useState<InvoiceResponse>();
  const [estimateInvoice, setEstimateInvoice] = useState<InvoiceResponse>();

  useEffect(() => {
    setEstimateInvoice(
      prop?.invoiceList?.find(
        (invoice) =>
          invoice.invoiceType === InvoiceType.ESTIMATE &&
          invoice.invoiceStatus === 'CREATED'
      )
    );

    setPaymentInvoice(
      prop?.invoiceList?.find(
        (invoice) =>
          invoice.invoiceType === InvoiceType.PAYMENT_INVOICE &&
          invoice.invoiceStatus === 'CREATED'
      )
    );
  }, [prop?.invoiceList]);

  return estimateInvoice || paymentInvoice ? (
    <div className={'flex flex-col gap-4'}>
      <h3 className={'uppercase text-primary'}>Estimates & Invoices</h3>
      {estimateInvoice && (
        <Link
          className={'w-full'}
          to={`/invoice?token=${token}`}
          state={{
            prop: estimateInvoice,
          }}
        >
          <Button
            variant={'contained'}
            className={'h-12 w-full'}
            sx={{ backgroundColor: COLORS.SECONDARY }}
          >
            Check Estimate
          </Button>
        </Link>
      )}
      {paymentInvoice && (
        <Link
          className={'w-full'}
          to={`/invoice?token=${token}`}
          state={{
            prop: paymentInvoice,
          }}
        >
          <Button variant={'contained'} className={'h-12 w-full'}>
            Check Invoice
          </Button>
        </Link>
      )}
      <Divider flexItem className={'w-full text-border-500'} />
    </div>
  ) : (
    <></>
  );
};

export default InvoiceView;
