import React from 'react';
import { Outlet } from 'react-router-dom';

const Layout: React.FC = () => {
  return (
    <div className={'w-full flex flex-col gap-4'}>
      <div className={'h-[8vh] px-4 fixed top-0 left-0 right-0 bg-white z-50'}>
        <img
          src={'/logo.png'}
          className={'flex px-2 py-4 w-48'}
          alt={'drivemo logo'}
        />
      </div>
      <div className={'px-4 mt-[8vh]'}>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
