export enum BookingType {
  EITHER_PUD = 'EITHER_PUD',
  BOTH_PUD = 'BOTH_PUD',
}

export enum BookingSubType {
  PICKUP = 'PICKUP',
  DROPOFF = 'DROPOFF',
}

export enum RenderedBookingType {
  PICKUP = 'PICKUP',
  DROPOFF = 'DROPOFF',
  PICKUP_AND_DROPOFF = 'PICKUP_AND_DROPOFF',
}

export enum BookingProgressStatus {
  ON_THE_WAY_TO_PICKUP = 'ON_THE_WAY_TO_PICKUP',
  ARRIVED_AT_PICKUP = 'ARRIVED_AT_PICKUP',
  PICKUP_OTP_VERIFIED = 'PICKUP_OTP_VERIFIED',
  PICKUP_IMAGES_UPLOADED = 'PICKUP_IMAGES_UPLOADED',
  ON_THE_WAY_TO_DROP = 'ON_THE_WAY_TO_DROP',
  ARRIVED_AT_DROP = 'ARRIVED_AT_DROP',
  DROP_OTP_VERIFIED = 'DROP_OTP_VERIFIED',
  END_TRIP = 'END_TRIP',
  CANCELLED = 'CANCELLED',
}

export enum BookingStatus {
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELLED = 'CANCELLED',
  RESCHEDULED = 'RESCHEDULED',
  COMPLETED = 'COMPLETED',
}

export enum InvoiceType {
  ESTIMATE = 'ESTIMATE',
  PAYMENT_INVOICE = 'PAYMENT_INVOICE',
}
