import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { InvoiceResponse } from '../dto/response/booking-detail-response';
import UpdateCard from '../components/shared/UpdateCard';
import { InvoiceType } from '../enums/booking-enums';
import FileRenderer from '../components/shared/FileRenderer';
import { Button } from '@mui/material';
import { useQuery } from 'react-query';
import { fetchPaymentInfo, startPayment } from '../services/payment';
import {
  GetOrderInfoResponse,
  PaymentMethod,
} from '../dto/response/get-payment-info';
import { StartPaymentResponse } from '../dto/response/start-payment-response';
import { toTitleCase } from '../utils/text';

export type InvoiceProp = {
  prop: InvoiceResponse;
};
const Invoices = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { prop }: InvoiceProp = location.state;
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');

  const { data: paymentInfo, refetch: refetchPaymentInfo } =
    useQuery<GetOrderInfoResponse>(
      'payment/uuid',
      () => fetchPaymentInfo(token || ''),
      {
        enabled: false,
        retry: false,
        onSuccess: (data) => {},
      }
    );

  const { data: payment, refetch: startPaymentRefetch } =
    useQuery<StartPaymentResponse>(
      'payment/start',
      () =>
        startPayment(token || '', {
          orderId: 1,
          paymentMethod: PaymentMethod.UPI,
        }),
      {
        enabled: false,
        retry: false,
        onSuccess: (data) => {
          console.log('data', data);
          navigate(`/payment?token=${token}`, {
            state: { prop: data, token: token },
          });
        },
      }
    );

  const handlePayNow = () => {
    startPaymentRefetch();
  };

  return (
    <div className={'flex flex-col gap-4'}>
      <div
        className={'flex gap-3 text-primary items-center'}
        onClick={() => navigate(-1)}
      >
        <i className={'ri-arrow-left-line text-xl'} />
        <h3 className={'text-xl'}>
          {prop.invoiceType === InvoiceType.PAYMENT_INVOICE
            ? 'Check Invoice'
            : 'Check Estimate'}
        </h3>
      </div>

      <UpdateCard
        title={'Payment Status'}
        description={
          prop.invoiceType === InvoiceType.PAYMENT_INVOICE
            ? `Payment ${prop.invoicePaymentStatus === null ? 'to be made' : ' ' + toTitleCase(prop.invoicePaymentStatus.toString())}`
            : 'Estimate Available'
        }
      />

      <div className={'flex flex-row justify-between w-full'}>
        <h3 className={'uppercase text-primary'}>
          {prop.invoiceType === InvoiceType.PAYMENT_INVOICE
            ? 'Invoice'
            : 'Estimate'}{' '}
          Details
        </h3>
        <h3 className={'uppercase text-gray-800'}>
          ID: #{prop?.invoiceNumber}
        </h3>
      </div>
      <div className={'border border-gray-300 rounded-xl p-2 bg-zinc-50'}>
        <FileRenderer url={prop?.invoiceUrl} />
      </div>
      <div className={'flex flex-row justify-between w-full items-end'}>
        <h3 className={'uppercase text-primary'}>
          {prop.invoiceType === InvoiceType.PAYMENT_INVOICE
            ? 'Invoice'
            : 'Estimate'}{' '}
          Amount
        </h3>
        <h3 className={'uppercase text-gray-800 text-2xl'}>
          INR {prop?.amount}
        </h3>
      </div>

      {prop.invoiceType === InvoiceType.PAYMENT_INVOICE &&
        prop.invoicePaymentStatus === null && (
          <Button
            className={'h-12'}
            variant={'contained'}
            onClick={handlePayNow}
          >
            Pay Now
          </Button>
        )}
      {prop.invoiceType === InvoiceType.PAYMENT_INVOICE &&
        prop.invoicePaymentStatus !== null && (
          <Button
            className={'h-12'}
            variant={'contained'}
            onClick={() => navigate(`/payment/status?token=${token}`)}
          >
            Check Payment Status
          </Button>
        )}
    </div>
  );
};

export default Invoices;
