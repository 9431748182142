import React from 'react';

import { Button, Modal } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setModalState } from '../store/modal/modalSlice';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const SuccessModal = () => {
  const dispatch = useAppDispatch();
  const { open: isOpen, payload } = useAppSelector(
    (state) => state.modal['share.location']
  );

  const handleClose = () => {
    dispatch(
      setModalState({
        modal: 'share.location',
        state: { open: false, payload: { path: 'home' } },
      })
    );
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      classes={{ root: 'flex items-center justify-center' }}
    >
      <div
        className={
          'relative bg-white w-3/4 h-1/3 rounded-xl flex flex-col gap-5 items-center justify-center py-4'
        }
        onClick={handleClose}
      >
        <CloseIcon
          className={
            'absolute top-2 right-3 ri-close-line text-zinc-900 bg-zinc-100 rounded p-1 text-xl hover:cursor-pointer'
          }
        />
        <div
          className={
            'flex justify-center items-center rounded-full w-24 h-24 bg-green-200'
          }
        >
          <div
            className={
              'flex justify-center items-center rounded-full w-20 h-20 bg-green-600'
            }
          >
            <CheckIcon className={'self-center text-white h-full w-full'} />
          </div>
        </div>
        <h3 className={'text-xl text-center font-semibold text-primary'}>
          Thank You for sharing your Location
        </h3>
        <Button className={'w-1/2'} onClick={handleClose}>
          Done
        </Button>
      </div>
    </Modal>
  );
};

export default SuccessModal;
