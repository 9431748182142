import React, { useEffect, useMemo, useState } from 'react';
import UpdateCard from '../components/shared/UpdateCard';
import DetailCard from '../components/shared/DetailCard';
import CardItem from '../components/shared/CardItem';
import ContactItem from '../components/shared/ContactItem';

import BookingTimeline from '../components/BookingTimeline';
import VehicleDetailSection from '../components/VehicleDetailSection';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BookingDetailResponse } from '../dto/response/booking-detail-response';
import { useQuery } from 'react-query';
import {
  fetchBookingDetail,
  fetchOtp,
  getCaptainLocation,
  getOtherBookingToken,
} from '../services/booking';
import {
  BookingProgressStatus,
  BookingStatus,
  BookingSubType,
  BookingType,
} from '../enums/booking-enums';
import {
  convertTime,
  convertTimePlusThirty,
  formatDateToDateString,
} from '../utils/datetime';
import ErrorPage from './ErrorPage';
import InvoiceView from '../components/InvoiceView';
import { OtpResponse } from '../dto/response/otp-response';
import { Button } from '@mui/material';
import { useAppDispatch } from '../store/hooks';
import { setModalState } from '../store/modal/modalSlice';
import toast from 'react-hot-toast';
import { GetBookingToken } from '../dto/response/get-booking-token';
import {
  dropForCustomerOrServiceCentre,
  pickupForCustomerOrServiceCentre,
} from '../utils/booking';
import { CaptainBookingLocationResponse } from '../dto/response/booking-location-response';
import BookingTracking from '../components/BookingTracking';
import { isEmpty } from 'lodash';

type UpdateCardType = {
  title: string;
  description: string;
};
const Home = () => {
  const [updateCard, setUpdateCard] = useState<UpdateCardType>();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    data: otp,
    refetch: refetchOtp,
    isLoading: isLoadingOtp,
  } = useQuery<OtpResponse>('otp', () => fetchOtp(token || ''), {
    enabled: false,
    onSuccess: (data) => {
      if (data.otp === null) {
        toast.error('No OTP is Generated Yet');
        return;
      }
      dispatch(
        setModalState({
          modal: 'view.booking.otp',
          state: { open: true, payload: { item: data.otp } },
        })
      );
    },
  });

  const {
    data: bookingDetail,
    refetch: refetchBookingDetail,
    isFetched,
  } = useQuery<BookingDetailResponse>(
    `booking/detail`,
    () => fetchBookingDetail(token || ''),
    {
      refetchInterval: 10000,
      enabled: !!token,
      refetchOnMount: true,
    }
  );

  const shouldGetPickupToken = useMemo(() => {
    return (
      bookingDetail?.bookingType === BookingType.BOTH_PUD &&
      bookingDetail?.bookingSubType === BookingSubType.DROPOFF
    );
  }, [bookingDetail]);

  const { data: otherBookingToken } = useQuery<GetBookingToken>(
    `other/booking/token`,
    () => getOtherBookingToken(token || ''),
    {
      enabled: shouldGetPickupToken,
      retry: false,
    }
  );

  const { data: captainLocation } = useQuery<CaptainBookingLocationResponse>(
    [`booking/location`],
    () => getCaptainLocation(token || ''),
    {
      enabled:
        !isEmpty(token) && bookingDetail?.status !== BookingStatus.CREATED,
      refetchOnMount: true,
      retry: 0,
      refetchIntervalInBackground: true,
      refetchInterval:
        bookingDetail?.status === BookingStatus.IN_PROGRESS ? 10000 : false,
    }
  );

  const handleFetchOtp = () => {
    refetchOtp();
  };

  useEffect(() => {
    if (
      bookingDetail?.status === BookingStatus.CREATED &&
      isEmpty(bookingDetail?.customerLocationResponse)
    ) {
      navigate('/edit/location?token=' + token, {
        state: { prop: bookingDetail },
      });
    }

    if (bookingDetail?.status === BookingStatus.CREATED) {
      setUpdateCard({
        title: 'Booking Created',
        description: 'Details will get updated when the trip starts',
      });
    }

    if (bookingDetail && bookingDetail.bookingProgressStatus) {
      const progressStatus: BookingProgressStatus =
        bookingDetail.bookingProgressStatus;
      switch (progressStatus) {
        case BookingProgressStatus.ON_THE_WAY_TO_PICKUP:
          setUpdateCard({
            title: 'On the way to Pickup',
            description: 'Captain is on the way to pickup location',
          });
          break;
        case BookingProgressStatus.ARRIVED_AT_PICKUP:
          setUpdateCard({
            title: `Arrived at ${pickupForCustomerOrServiceCentre(bookingDetail.bookingSubType)} Location`,
            description: `Captain has reached the ${pickupForCustomerOrServiceCentre(bookingDetail.bookingSubType)} location`,
          });
          break;
        case BookingProgressStatus.PICKUP_OTP_VERIFIED:
          setUpdateCard({
            title: 'Pickup OTP Verified',
            description: 'Captain has verified the OTP',
          });
          break;
        case BookingProgressStatus.PICKUP_IMAGES_UPLOADED:
          setUpdateCard({
            title: 'Pickup Photos Clicked',
            description: 'Captain has Clicked Pickup Photos',
          });
          break;
        case BookingProgressStatus.ON_THE_WAY_TO_DROP:
          setUpdateCard({
            title: 'On the way to Dropoff',
            description: 'Captain is on the way to dropoff location',
          });
          break;
        case BookingProgressStatus.ARRIVED_AT_DROP:
          setUpdateCard({
            title: `Arrived at ${dropForCustomerOrServiceCentre(bookingDetail.bookingSubType)} Location`,
            description: `Captain has reached the ${dropForCustomerOrServiceCentre(bookingDetail.bookingSubType)} location`,
          });
          break;
        case BookingProgressStatus.DROP_OTP_VERIFIED:
          setUpdateCard({
            title: 'Dropoff OTP Verified',
            description: 'Captain has verified the OTP',
          });
          break;
        case BookingProgressStatus.END_TRIP:
          setUpdateCard({
            title: 'Trip Completed',
            description: 'Captain has completed the trip',
          });
          break;
      }
    }
  }, [bookingDetail]);

  return bookingDetail ? (
    <div className={'flex flex-col gap-5'}>
      {shouldGetPickupToken && (
        <a
          href={`/${otherBookingToken?.token}`}
          target="_blank"
          rel="noopener noreferrer"
          className={'w-full flex justify-end text-primary'}
        >
          Open PickUp Booking{' '}
          <i className="ri-arrow-right-up-line text-primary"></i>
        </a>
      )}
      <UpdateCard
        title={updateCard?.title || ''}
        description={updateCard?.description || ''}
      />

      {bookingDetail.status === BookingStatus.IN_PROGRESS && (
        <BookingTracking
          prop={bookingDetail}
          captainLocation={captainLocation}
        />
      )}

      <InvoiceView prop={bookingDetail} />

      <div className={'flex flex-row justify-between w-full'}>
        <h3 className={'uppercase text-primary'}>
          {bookingDetail.bookingSubType} Details
        </h3>
        <h3 className={'uppercase text-gray-800'}>
          ID: #{bookingDetail?.bookingReferenceNumber}
        </h3>
      </div>
      <DetailCard>
        <div className={'flex flex-row gap-2 w-full'}>
          <CardItem
            title={'DATE'}
            value={formatDateToDateString(bookingDetail?.scheduledForDate)}
            image={'calendar.png'}
          />
          <CardItem
            title={'TIME'}
            value={
              convertTime(bookingDetail?.scheduledForTime) +
              '-' +
              convertTimePlusThirty(bookingDetail?.scheduledForTime)
            }
            image={'clock.png'}
          />
        </div>
      </DetailCard>

      {((bookingDetail?.bookingProgressStatus ===
        BookingProgressStatus.ARRIVED_AT_PICKUP &&
        bookingDetail.bookingSubType === BookingSubType.PICKUP) ||
        (bookingDetail?.bookingProgressStatus ===
          BookingProgressStatus.ARRIVED_AT_DROP &&
          bookingDetail.bookingSubType === BookingSubType.DROPOFF)) && (
        <Button className={'h-12'} onClick={handleFetchOtp}>
          Get OTP to Share with Captain
        </Button>
      )}

      <div className={'grid grid-cols-2 w-full gap-4 justify-between'}>
        {bookingDetail.serviceCenterRepresentative &&
          bookingDetail?.serviceCenterRepresentative.mobileNumber !==
            bookingDetail?.customerRelationRepresentative.mobileNumber && (
            <DetailCard className={'w-full justify-center text-center px-2'}>
              <ContactItem
                title={'service advisor'}
                value={`${bookingDetail?.serviceCenterRepresentative.name}`}
                phone={
                  bookingDetail?.serviceCenterRepresentative.mobileNumber || ''
                }
              />
            </DetailCard>
          )}

        <DetailCard className={'w-full justify-center text-center px-2'}>
          <ContactItem
            title={'Customer Relationship Executive'}
            value={`${bookingDetail?.customerRelationRepresentative.name}`}
            phone={
              bookingDetail?.customerRelationRepresentative.mobileNumber || ''
            }
          />
        </DetailCard>

        {bookingDetail?.bookingProgressStatus !==
          BookingProgressStatus.END_TRIP && (
          <DetailCard className={'w-full justify-center text-center px-2'}>
            <ContactItem
              title={'Driver'}
              value={bookingDetail?.captain?.captainName || ''}
              phone={bookingDetail?.captain?.captainMobileNumber || ''}
            />
          </DetailCard>
        )}
      </div>

      <VehicleDetailSection prop={bookingDetail} />

      {bookingDetail?.status !== BookingStatus.CREATED && (
        <BookingTimeline prop={bookingDetail} />
      )}
    </div>
  ) : isFetched ? (
    <ErrorPage />
  ) : (
    <div className={'flex w-full h-full justify-center items-center'}>
      Loading...
    </div>
  );
};

export default Home;
