import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { DocumentCallback } from 'react-pdf/src/shared/types';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type FileRendererProps = {
  url: string;
};
const FileRenderer: React.FC<FileRendererProps> = ({ url }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess(document: DocumentCallback) {
    setNumPages(document.numPages);
  }

  const fileExtension = url?.split('.').pop()?.toLowerCase();

  switch (fileExtension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return (
        <img
          src={url}
          alt="File"
          className={'object-contain w-full h-[40vh]'}
        />
      );

    case 'pdf':
      return (
        <div className={'h-fit w-full flex justify-center items-center'}>
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            renderMode={'canvas'}
            options={{ workerSrc: pdfjs.GlobalWorkerOptions.workerSrc }}
          >
            <Page
              pageNumber={1}
              height={400}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </Document>
        </div>
      );

    default:
      // Handle unsupported file types
      return <div>Unsupported file type</div>;
  }
};

export default FileRenderer;
