import {
  BookingSubType,
  BookingType,
  RenderedBookingType,
} from '../enums/booking-enums';
import {
  DropMediaDto,
  PickupMediaDto,
} from '../dto/response/booking-detail-response';
import { PhotoProp } from '../pages/Photos';

export const getRenderBookingType = (
  bookingType: BookingType,
  bookingSubType: BookingSubType
): RenderedBookingType => {
  switch (bookingType) {
    case BookingType.EITHER_PUD:
      switch (bookingSubType) {
        case BookingSubType.PICKUP:
          return RenderedBookingType.PICKUP;
        case BookingSubType.DROPOFF:
          return RenderedBookingType.DROPOFF;
      }
      break;
    case BookingType.BOTH_PUD:
      return RenderedBookingType.PICKUP_AND_DROPOFF;
  }
};

export const pickupForCustomerOrServiceCentre = (
  bookingSubType: BookingSubType
): string => {
  switch (bookingSubType) {
    case BookingSubType.PICKUP:
      return 'Your';
    case BookingSubType.DROPOFF:
      return 'Service Centre';
  }
};

export const pickupForCustomerOrServiceCentreForMap = (
  bookingSubType: BookingSubType
): string => {
  switch (bookingSubType) {
    case BookingSubType.PICKUP:
      return 'Customer';
    case BookingSubType.DROPOFF:
      return 'Service Centre';
  }
};

export const dropForCustomerOrServiceCentreForMap = (
  bookingSubType: BookingSubType
): string => {
  switch (bookingSubType) {
    case BookingSubType.PICKUP:
      return 'Service Centre';
    case BookingSubType.DROPOFF:
      return 'Customer';
  }
};

export const dropForCustomerOrServiceCentre = (
  bookingSubType: BookingSubType
): string => {
  switch (bookingSubType) {
    case BookingSubType.PICKUP:
      return 'Service Centre';
    case BookingSubType.DROPOFF:
      return 'Your';
  }
};

export const convertPickupMediaToCapturedPhotos = (
  pickupMedia: PickupMediaDto
): PhotoProp[] => {
  const photos: PhotoProp[] = Object.keys(pickupMedia).map((key) => ({
    url: pickupMedia[key as keyof PickupMediaDto],
    name: camelCaseToSpace(key),
  }));

  return photos;
};

export const convertDropMediaToCapturedPhotos = (
  dropMedia: DropMediaDto
): PhotoProp[] => {
  const photos: PhotoProp[] = Object.keys(dropMedia).map((key) => ({
    url: dropMedia[key as keyof DropMediaDto],
    name: camelCaseToSpace(key),
  }));

  return photos;
};

function camelCaseToSpace(camelCaseString: string) {
  const regex = /([A-Z])/g;

  // Replace the matched uppercase letters with a space and the letter itself
  const spaceSeparated = camelCaseString.replace(regex, ' $1');

  // For the first letter, capitalize it
  return spaceSeparated.charAt(0).toUpperCase() + spaceSeparated.slice(1);
}
