import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import buttonReducer from './button/buttonSlice';
import authReducer from './auth/authSlice';
import modalReducer from './modal/modalSlice';
import storage from './storage';

const authPersistConfig = {
  key: 'auth-track',
  storage: storage,
};

const buttonPersistConfig = {
  key: 'button-track',
  storage: storage,
};

const reducers = combineReducers({
  button: persistReducer(buttonPersistConfig, buttonReducer),
  auth: persistReducer(authPersistConfig, authReducer),
  modal: modalReducer,
});

const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});

persistStore(store);

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
