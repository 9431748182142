import { Close as CloseIcon } from '@mui/icons-material';
import { Fade, IconButton, Modal } from '@mui/material';
import styles from './BaseModal.module.scss';

import React from 'react';

type Props = {
  isOpen: boolean;
  heading: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  footerChildren?: React.ReactNode;
  handleClose: () => void;
};

const BaseModal: React.FC<Props> = ({
  icon,
  isOpen,
  heading,
  children,
  handleClose,
  footerChildren,
}) => {
  const onClose = (event: any, reason: string) => {
    handleClose();
  };

  const onIconClose = () => {
    handleClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => onClose(event, reason)}
      closeAfterTransition
      aria-labelledby={heading}
      classes={{ root: 'flex items-center justify-center' }}
    >
      <Fade in={isOpen}>
        <div className={styles.content}>
          <header className={styles.header}>
            <div>
              {icon}
              <h1>{heading}</h1>
            </div>

            <IconButton
              size="small"
              onClick={onIconClose}
              sx={{
                backgroundColor: '#E9E9E9',
                width: '2rem',
                height: '2rem',
                borderRadius: '0.2rem',
                marginLeft: '2rem',
              }}
            >
              <CloseIcon sx={{ fontSize: '1.2rem' }} />
            </IconButton>
          </header>

          <div className={styles.body}>{children}</div>

          {footerChildren ? (
            <footer className={styles.footer}>{footerChildren}</footer>
          ) : null}
        </div>
      </Fade>
    </Modal>
  );
};

export default BaseModal;
