import React from 'react';

type ContactItemProps = {
  title: string;
  value: string;
  phone: string;
};
const ContactItem: React.FC<ContactItemProps> = ({ title, value, phone }) => {
  return (
    <div className={'flex flex-col justify-between'}>
      <div className={'flex flex-col gap-1'}>
        <img src={'man.png'} className={'w-10 h-10 self-center'} />
        <h3 className={'uppercase text-gray-400 text-sm'}>{title}</h3>
        <h3 className={'text-gray-800'}>{value}</h3>
      </div>
      <div>
        <div
          className={
            'border border-gray-300 rounded w-full flex items-center justify-center gap-1 px-1 py-2 text-primary'
          }
        >
          <i className={'ri-phone-fill self-center text-center text-2xl'} />
          <a href={`tel:${phone}`}>Call Now</a>
        </div>
      </div>
    </div>
  );
};

export default ContactItem;
