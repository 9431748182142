import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BookingProp } from '../dto/BookingProp';
import FuelGauge from '../components/FuelGauge';

const DigitalInventory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { prop }: BookingProp = location.state;

  return (
    <div className={'flex flex-col gap-4'}>
      <div
        className={'flex gap-3 text-primary items-center'}
        onClick={() => navigate(-1)}
      >
        <i className={'ri-arrow-left-line text-xl'} />
        <h3 className={'text-xl'}>Captured Digital Inventory</h3>
      </div>

      <div className={'flex flex-col gap-6'}>
        <div className={'flex flex-col gap-1'}>
          <h3>Kilometre Reading</h3>
          <div className={'border border-zinc-300 p-4 rounded bg-zinc-50'}>
            {prop?.digitalInventory?.vehicleKmReading}Kms
          </div>
        </div>
        <FuelGauge level={prop?.digitalInventory?.fuelGauge || 0} />
        <div className={'flex flex-col gap-1'}>
          <h3>Notes</h3>
          <div className={'border border-zinc-300 p-4 rounded bg-zinc-50 h-32'}>
            {prop?.digitalInventory?.notes}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalInventory;
