import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../store/hooks';
import { setModalState } from '../store/modal/modalSlice';
import { BookingProp } from '../dto/BookingProp';
import {
  convertDropMediaToCapturedPhotos,
  convertPickupMediaToCapturedPhotos,
} from '../utils/booking';

import ToggleButton, { ButtonType } from '../components/ToggleButton';
import { isEmpty } from 'lodash';

export type CapturedPhotosProps = {
  photos?: PhotoProp[];
};

export type PhotoProp = {
  url: string;
  name: string;
};

const Photos: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { prop }: BookingProp = location.state;
  const [photos, setPhotos] = useState<PhotoProp[]>([]);
  const dispatch = useAppDispatch();
  const [toggleActiveSection, setToggleActiveSection] =
    useState<ButtonType>('pickup');

  useEffect(() => {
    if (toggleActiveSection === 'pickup') {
      if (!prop?.pickupMedia) {
        setPhotos([]);
        return;
      }
      setPhotos(convertPickupMediaToCapturedPhotos(prop?.pickupMedia));
    } else if (toggleActiveSection === 'dropoff') {
      if (!prop?.dropMedia) {
        setPhotos([]);
        return;
      }
      setPhotos(convertDropMediaToCapturedPhotos(prop?.dropMedia));
    }
  }, [prop, toggleActiveSection]);
  const handlePhotoClick = (photo: PhotoProp) => {
    dispatch(
      setModalState({
        modal: 'view.photo',
        state: {
          open: true,
          payload: {
            item: photo,
          },
        },
      })
    );
  };

  return (
    <div className={'flex flex-col gap-4'}>
      <div
        className={'flex gap-3 text-primary items-center'}
        onClick={() => navigate(-1)}
      >
        <i className={'ri-arrow-left-line text-xl'} />
        <h3 className={'text-xl'}>Captured Photos</h3>
      </div>

      <ToggleButton
        active={toggleActiveSection}
        setActive={setToggleActiveSection}
      />

      <div className={'grid grid-cols-3 gap-3'}>
        {photos
          ?.filter((photo) => photo.url !== null)
          .map((photo, index: React.Key | null | undefined) => (
            <div
              key={index}
              className={'flex flex-col w-full h-fit gap-2 text-lg'}
            >
              <img
                src={photo.url}
                className={'rounded-xl h-40 object-cover'}
                onClick={() => handlePhotoClick(photo)}
              />
              <h4 className={'break-words'}>{photo.name}</h4>
            </div>
          ))}
      </div>

      {isEmpty(photos) && (
        <div className={'text-center flex justify-center items-center'}>
          Photos Will Be Visible as soon as they get clicked by our Captain
        </div>
      )}
    </div>
  );
};

export default Photos;
