import _axios, { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import store from '../store';
import { setModalState } from '../store/modal/modalSlice';
import { logout } from '../store/auth/authSlice';
// Function to initialize Axios instance with interceptors

export type ServerError = {
  message: string;
};

export const createAxiosInstance = (
  baseURL: string,
  showLoader: boolean,
  showError: boolean = true
) => {
  const instance = _axios.create({ baseURL });

  instance.interceptors.request.use((config) => {
    showLoader &&
      store.dispatch(
        setModalState({ modal: 'global.loader', state: { open: true } })
      );

    // const { token: accessToken } = store.getState().auth;
    // if (accessToken !== undefined) {
    //   config.headers.Authorization = accessToken;
    // }

    // Set latitude and longitude
    config.headers.latitude = 23.8234; // Replace with actual geolocation
    config.headers.longitude = 84.8234; // Replace with actual geolocation

    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      showLoader &&
        store.dispatch(
          setModalState({ modal: 'global.loader', state: { open: false } })
        );
      return response;
    },
    (error: AxiosError<ServerError>) => {
      showLoader &&
        store.dispatch(
          setModalState({ modal: 'global.loader', state: { open: false } })
        );

      if (error.response) {
        const errorObject = error.response.data;
        const message = errorObject.message;
        if (showError) {
          toast.error(message);
        }
        if (error.response.status === 401) {
          store.dispatch(logout());
          // Router.push("/login").then((r) => r);
        }
      } else {
        if (showError) {
          toast.error(error.message);
        }
      }

      throw error;
    }
  );

  return instance;
};

// Example of using the function
export const userServiceAxios = createAxiosInstance(
  process.env.REACT_APP_API_BASE_URL || 'https://backend.drivemo.in',
  true
);

export const paymentServiceAxios = createAxiosInstance(
  process.env.REACT_APP_PAYMENT_API_BASE_URL || 'https://payments.drivemo.in',
  true
);

export const userServiceAxiosNoLoader = createAxiosInstance(
  process.env.REACT_APP_API_BASE_URL || 'https://backend.drivemo.in',
  false
);

export const userServiceAxiosNoLoaderNoError = createAxiosInstance(
  process.env.REACT_APP_API_BASE_URL || 'https://backend.drivemo.in',
  false,
  false
);
