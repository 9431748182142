import React, { useEffect, useState } from 'react';
import {
  AdvancedMarker,
  APIProvider,
  InfoWindow,
  Map,
  MapCameraChangedEvent,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { isEmpty } from 'lodash';

export type MapComponentProps = {
  id?: string;
  center: { lat: number; lng: number };
  addressName?: string;
  className?: string;
  disablePanning?: boolean;
  setCenter?: (center: { lat: number; lng: number }) => void;
  children?: React.ReactNode;
};

const MapComponent: React.FC<MapComponentProps> = ({
  center,
  className,
  addressName,
  disablePanning = false,
  setCenter,
  id,
  children,
}) => {
  const [infoWindowOpen, setInfoWindowOpen] = useState(true);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [markerPosition, setMarkerPosition] = useState(center);

  useEffect(() => {
    setMarkerPosition(center);
  }, [center]);

  const handleCenterChanged = (map: MapCameraChangedEvent) => {
    const newCenter = map.map.getCenter();
    setMarkerPosition({
      lat: newCenter?.lat() || 0,
      lng: newCenter?.lng() || 0,
    });
    if (setCenter) {
      setCenter({
        lat: newCenter?.lat() || 0,
        lng: newCenter?.lng() || 0,
      });
    }
  };

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_KEY || ''}>
      <Map
        id={id}
        mapId={'a4f9585b40b0566e'}
        center={center}
        zoom={16}
        onCenterChanged={handleCenterChanged}
        disableDefaultUI
        className={`relative w-full border rounded-xl ${className}`}
        gestureHandling={disablePanning ? 'none' : 'greedy'}
      >
        <AdvancedMarker
          onClick={() => setInfoWindowOpen(true)}
          position={markerPosition}
          draggable={false}
          ref={markerRef}
          className={'w-fit'}
        >
          <img src={'/custom-pin.png'} alt={'decoration'} className={'w-52'} />
        </AdvancedMarker>
        {infoWindowOpen && !isEmpty(addressName) && (
          <InfoWindow
            anchor={marker}
            maxWidth={200}
            onCloseClick={() => setInfoWindowOpen(false)}
          >
            {addressName}
          </InfoWindow>
        )}
        {children}
      </Map>
    </APIProvider>
  );
};

export default MapComponent;
