import React, {useMemo} from "react";
import {ThemeProvider} from "@mui/system";
import {theme} from "../config/theme";


const ThemeWrapper: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
    const muiTheme = useMemo(() => theme, []);
    return <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>;
};

export default ThemeWrapper;
