import { BookingDetailResponse } from '../dto/response/booking-detail-response';
import {
  userServiceAxios,
  userServiceAxiosNoLoader,
  userServiceAxiosNoLoaderNoError,
} from './axios';
import { OtpResponse } from '../dto/response/otp-response';
import { UuidResponse } from '../dto/response/uuid-response';
import { GetBookingToken } from '../dto/response/get-booking-token';
import { CaptainBookingLocationResponse } from '../dto/response/booking-location-response';
import { AxiosResponse } from 'axios';
import { CustomerLocationResponse } from '../dto/response/booking-prefill-data-response';

export const fetchBookingDetail = async (
  token: string
): Promise<BookingDetailResponse> => {
  return userServiceAxiosNoLoader
    .get<BookingDetailResponse>('/api/v1/end-user/get-booking-detail', {
      params: {
        token,
      },
      headers: {
        token,
      },
    })
    .then((res) => res.data);
};

export const fetchOtp = async (token: string): Promise<OtpResponse> => {
  return userServiceAxios
    .get<OtpResponse>('/api/v1/end-user/otp', {
      params: {
        token,
      },
      headers: {
        token,
      },
    })
    .then((res) => res.data);
};

export const fetchPaymentUuid = async (
  token: string,
  invoiceId: number
): Promise<UuidResponse> => {
  return userServiceAxios
    .get<UuidResponse>('/api/v1/end-user/payment/uuid', {
      params: {
        token,
        invoiceId,
      },
      headers: {
        token,
      },
    })
    .then((res) => res.data);
};

export const getOtherBookingToken = async (
  token: string
): Promise<GetBookingToken> => {
  return userServiceAxios
    .get<GetBookingToken>('/api/v1/end-user/booking/token', {
      params: {
        token,
      },
      headers: {
        token,
      },
    })
    .then((res) => res.data);
};

export const getCaptainLocation = async (
  token: string
): Promise<CaptainBookingLocationResponse> => {
  return userServiceAxiosNoLoaderNoError
    .get<CaptainBookingLocationResponse>('/api/v1/end-user/captain/location', {
      params: {
        token,
      },
      headers: {
        token,
      },
    })
    .then((res) => res.data);
};

export const editCustomerLocation = async (
  editLocationRequest: CustomerLocationResponse
) => {
  return await userServiceAxios
    .put<null, AxiosResponse<null>, CustomerLocationResponse>(
      '/api/v1/end-user/update/location',
      editLocationRequest,
      {
        headers: {
          token: editLocationRequest.token,
        },
      }
    )
    .then((res) => res.data);
};

export type AddressAutoCompleteResponse = {
  address: string;
  placeId: string;
  locationName: string;
  latitude: number;
  longitude: number;
};

export const fetchAddressesForAutoComplete = (
  query: string,
  token: string,
  sessionToken: string
): Promise<AddressAutoCompleteResponse[]> => {
  return userServiceAxiosNoLoader
    .get<any>('/api/v1/end-user/autocomplete', {
      params: {
        token,
        query,
        sessionToken,
      },
    })
    .then((res) => res.data);
};

export const fetchCompleteAddress = (
  query: string,
  token: string,
  sessionToken: string
): Promise<AddressAutoCompleteResponse> => {
  return userServiceAxios
    .get<any>('/api/v1/end-user/complete/address', {
      params: {
        token,
        query,
        sessionToken,
      },
    })
    .then((res) => res.data);
};
