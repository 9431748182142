import {
  BookingProgressStatus,
  BookingStatus,
  BookingSubType,
  BookingType,
  InvoiceType,
} from '../../enums/booking-enums';
import {
  CustomerDetail,
  CustomerLocationResponse,
} from './booking-prefill-data-response';
import { LocationDetail } from './booking-location-response';

export type BookingDetailResponse = {
  status: BookingStatus;
  bookingCaptainType: BookingCaptainType;
  branchLocation: LocationDetail;
  scheduledForDate: Date;
  scheduledForTime: string;
  notes?: string;
  serviceCenterRepresentative: PlatformUserBookingResponse;
  customerRelationRepresentative: PlatformUserBookingResponse;
  customerLocationResponse: CustomerLocationResponse;
  customer: CustomerDetail;
  bookingReferenceNumber: string;
  bookingProgressStatus?: BookingProgressStatus;
  pickupMedia?: PickupMediaDto;
  dropMedia?: DropMediaDto;
  captain?: CaptainBookingDetailDto;
  digitalInventory?: DigitalInventoryResponse;
  bookingSubType: BookingSubType;
  bookingType: BookingType;
  captainStartedTripAt?: Date;
  reachedPickupLocationAt?: Date;
  pickupOtpVerifiedAt?: Date;
  leftPickupLocationAt?: Date;
  pickUpImagesUploadedAt?: Date;
  reachedDropOffLocationAt?: Date;
  dropOtpVerifiedAt?: Date;
  captainEndTripAt?: Date;
  invoiceList: InvoiceResponse[];
};

export enum BookingCaptainType {
  DRIVEMO_CAPTAIN = 'DRIVEMO_CAPTAIN',
  BRANCH_CAPTAIN = 'BRANCH_CAPTAIN',
}

export type PickupMediaDto = {
  frontView: string;
  leftFrontWheel: string;
  leftFrontDoor: string;
  leftRearDoor: string;
  leftRearShoulder: string;
  rearView: string;
  trunkView: string;
  spareWheel: string;
  rightRearShoulder: string;
  rightRearDoor: string;
  rightFrontDoor: string;
  rightFrontWheel: string;
  gloveBox: string;
  centreConsole: string;
  mileageFuel: string;
  seatCover: string;
  key: string;
};

export type DropMediaDto = {
  frontView: string;
  leftFrontWheel: string;
  leftFrontDoor: string;
  leftRearDoor: string;
  leftRearShoulder: string;
  rearView: string;
  trunkView: string;
  spareWheel: string;
  rightRearShoulder: string;
  rightRearDoor: string;
  rightFrontDoor: string;
  rightFrontWheel: string;
  gloveBox: string;
  centreConsole: string;
  mileageFuel: string;
  seatCover: string;
  key: string;
};

type CaptainBookingDetailDto = {
  captainName: string;
  captainMobileNumber: string;
};

type DigitalInventoryResponse = {
  vehicleKmReading: number;
  notes: string;
  fuelGauge: number;
};

export type InvoiceResponse = {
  invoiceId: number;
  invoiceNumber: string;
  createdAt: Date;
  invoiceType: InvoiceType;
  invoicePaymentStatus: InvoicePaymentStatus;
  amount: number;
  invoiceUrl: string;
  invoiceStatus: 'CREATED' | 'DELETED';
};

export enum InvoicePaymentStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',

  PENDING = 'PENDING',
}

export type PlatformUserBookingResponse = {
  name: string;
  mobileNumber: string;
};
