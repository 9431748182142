import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ModalName =
  | 'global.loader'
  | 'view.photo'
  | 'view.booking.otp'
  | 'share.location';

export type ModalState = {
  open: boolean;
  payload?: {
    path?: string;
    item?: any;
    onComplete?: (newItem: any) => void;
    extra?: any;
  };
};

type PayloadType = { modal: ModalName; state: ModalState };

const initialState: Record<ModalName, ModalState> = {
  'global.loader': { open: false },
  'view.photo': { open: false },
  'view.booking.otp': { open: false },
  'share.location': { open: false },
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalState: (
      state: Record<ModalName, ModalState>,
      action: PayloadAction<PayloadType>
    ) => {
      state[action.payload.modal] = action.payload.state;
    },
  },
});

export const { setModalState } = modalSlice.actions;
export default modalSlice.reducer;
