import React, { useEffect, useRef } from 'react';
import { StartPaymentResponse } from '../dto/response/start-payment-response';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { completePayment } from '../services/payment';
import { OrderStatusResponse } from '../dto/response/order-status-response';

// Function to load script and append in DOM tree.
const loadScript = (src: string) =>
  new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      console.log('razorpay loaded successfully');
      resolve(true);
    };
    script.onerror = () => {
      console.log('error in loading razorpay');
      resolve(false);
    };
    document.body.appendChild(script);
  });

export type RenderRazorpayProps = {
  prop: StartPaymentResponse;
  token: string;
};
const RenderRazorpay: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { prop, token }: RenderRazorpayProps = location.state;
  const paymentId = useRef(null);
  const paymentMethod = useRef(null);

  const { data: paymentInfo, refetch: completePaymentRefetch } =
    useQuery<OrderStatusResponse>(
      'compelete/payemnt',
      () => completePayment(token || ''),
      {
        enabled: false,
        retry: false,
        onSuccess: (data) => {
          navigate(`/payment/status?token=${token}`);
        },
        onError: (error) => {
          navigate(`/payment/status?token=${token}`);
        },
      }
    );

  // To load razorpay checkout modal script.
  const displayRazorpay = async (options: any) => {
    console.log('displayRazorpay', options);
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
      console.log('Razorpay SDK failed to load. Are you online?');
      return;
    }
    // All information is loaded in options which we will discuss later.
    // @ts-ignore
    const rzp1 = new window.Razorpay(options);

    // If you want to retreive the chosen payment method.
    rzp1.on('payment.submit', (response: any) => {
      paymentMethod.current = response.method;
    });

    // To get payment id in case of failed transaction.
    rzp1.on('payment.failed', (response: any) => {
      paymentId.current = response.error.metadata.payment_id;
    });

    // to open razorpay checkout modal.
    rzp1.open();
  };

  // informing server about payment
  const handlePayment = async (status: any, orderDetails = {}) => {};
  console.log('prop', prop);
  // we will be filling this object in next step.
  const options = {
    key: prop.paymentGatewayAttributes?.key || '', // key id from props
    amount: prop.amount, //Amount in lowest denomination from props
    currency: 'INR', // Currency from props.
    name: 'DriveMo', // Title for your organization to display in checkout modal
    image: 'https://drivemoprd.azureedge.net/icon.png',
    order_id: prop.pgOrderId, // order id from props
    prefill: {
      contact: prop.mobileNumber,
    },
    send_sms_hash: true,
    // This handler menthod is always executed in case of succeeded payment
    handler: async (response: any) => {
      console.log('succeeded');
      console.log(response);
      paymentId.current = response.razorpay_payment_id;
      console.log('paymentId', paymentId.current);
      // Most important step to capture and authorize the payment. This can be done of Backend server.
      const succeeded = true;
      await completePaymentRefetch();
      // If successfully authorized. Then we can consider the payment as successful.
      if (succeeded) {
      } else {
      }
    },
    modal: {
      confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
      // This function is executed when checkout modal is closed
      // There can be 3 reasons when this modal is closed.
      ondismiss: async (reason: any) => {
        // const {
        //   reason: paymentReason,
        //   field,
        //   step,
        //   code,
        // } = reason && reason.error ? reason.error : {};
        // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly.
        if (reason === undefined) {
          console.log('cancelled');
          handlePayment('Cancelled');
        }
        // Reason 2 - When modal is auto closed because of time out
        else if (reason === 'timeout') {
          console.log('timedout');
          handlePayment('timedout');
        }
        // Reason 3 - When payment gets failed.
        else {
          console.log('failed');
        }
      },
    }, // This property allows to enble/disable retries.
    // This is enabled true by default.
    retry: {
      enabled: false,
    },
    timeout: 300, // Time limit in Seconds
    theme: {
      color: '#5307B4', // Custom color for your checkout modal.
    },
  };

  useEffect(() => {
    displayRazorpay(options);
  }, []);

  return null;
};

export default RenderRazorpay;
