import React from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setModalState } from '../store/modal/modalSlice';
import { Modal } from '@mui/material';

const GlobalLoaderModal = () => {
  const dispatch = useAppDispatch();

  const { open: isOpen, payload } = useAppSelector(
    (state) => state.modal['global.loader']
  );

  const handleClose = () => {
    dispatch(setModalState({ modal: 'global.loader', state: { open: false } }));
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      classes={{ root: 'flex items-center justify-center' }}
    >
      <div className="relative bg-white">
        <div>Loading....</div>
      </div>
    </Modal>
  );
};

export default GlobalLoaderModal;
