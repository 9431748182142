import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchPaymentDetails } from '../services/payment';
import { GetPaymentDetailsResponse } from '../dto/response/get-payment-details';
import UpdateCard from '../components/shared/UpdateCard';
import { formatDateToString } from '../utils/datetime';
import { OrderStatus } from '../dto/response/get-payment-info';

const PaymentStatus = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const navigate = useNavigate();

  const token = searchParams.get('token');

  const { data: paymentDetails, refetch: refetchPaymentDetails } =
    useQuery<GetPaymentDetailsResponse>(
      ['payment/details', token],
      () => fetchPaymentDetails(token || ''),
      {
        enabled: !!token,
        retry: false,
        onSuccess: (data) => {},
      }
    );

  useEffect(() => {
    refetchPaymentDetails();
  }, []);

  const paymentTitle: string = useMemo(() => {
    switch (paymentDetails?.orderStatus) {
      case OrderStatus.INIT: {
        return 'Payment Initiated';
      }
      case OrderStatus.PENDING: {
        return 'Payment Pending';
      }
      case OrderStatus.SUCCESS: {
        return 'Payment Success';
      }
      case OrderStatus.FAILED: {
        return 'Payment Failed';
      }
      default: {
        return 'Payment Initiated';
      }
    }
  }, [paymentDetails]);

  return (
    <div className={'flex flex-col gap-5'}>
      <div
        className={'flex gap-3 text-primary items-center'}
        onClick={() => navigate(`/${token}`)}
      >
        <i className={'ri-arrow-left-line text-xl'} />
        <h3 className={'text-xl'}>Back to Home</h3>
      </div>

      <UpdateCard title={'Payment Status'} description={paymentTitle} />
      <DisplayTile
        title={'Transaction Id'}
        subtitle={paymentDetails?.transactionId}
      />
      <DisplayTile
        title={'Amount'}
        subtitle={`INR ${paymentDetails?.amount}`}
      />
      <DisplayTile
        title={'Payment Date'}
        subtitle={formatDateToString(paymentDetails?.orderDate)}
      />
      <DisplayTile title={'Payment Mode'} subtitle={paymentDetails?.paidFrom} />
    </div>
  );
};

type DisplayTileProps = {
  title: string;
  subtitle?: string;
};

const DisplayTile: React.FC<DisplayTileProps> = ({ title, subtitle }) => {
  return (
    <div className={'flex flex-col gap-2 px-2'}>
      <h3 className={'uppercase text-gray-500 text-sm'}>{title}</h3>
      <h3>{subtitle}</h3>
    </div>
  );
};

export default PaymentStatus;
