import React from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setModalState } from '../store/modal/modalSlice';
import BaseModal from '../components/shared/BaseModal';

const ViewBookingOtpModal = () => {
  const dispatch = useAppDispatch();

  const { open: isOpen, payload } = useAppSelector(
    (state) => state.modal['view.booking.otp']
  );
  const handleClose = () => {
    dispatch(
      setModalState({ modal: 'view.booking.otp', state: { open: false } })
    );
  };

  const otpDigits = String(payload?.item).split('');

  return (
    <BaseModal
      isOpen={isOpen}
      heading={'Booking OTP'}
      handleClose={handleClose}
    >
      <div className={'flex flex-row justify-center text-center gap-2'}>
        {otpDigits?.map((digit, index) => (
          <div
            key={index}
            className={
              'w-12 h-12 bg-zinc-100 rounded flex items-center justify-center'
            }
          >
            <h3 className={'text-2xl'}>{digit}</h3>
          </div>
        ))}
      </div>
      <div className={'text-gray-500'}>
        Only Share this OTP with the Driver who has arrived to pickup the
        vehicle only when he is at your location.
      </div>
    </BaseModal>
  );
};

export default ViewBookingOtpModal;
