import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ButtonState = {
  submitButtonDisabled?: boolean;
  showOtpSection?: boolean;
};

const initialState: ButtonState = {
  submitButtonDisabled: true,
  showOtpSection: false,
};

export const buttonSlice = createSlice({
  name: "button",
  initialState,
  reducers: {
    setSubmitButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.submitButtonDisabled = action.payload;
    },
    setShowOtpSection: (state, action: PayloadAction<boolean>) => {
      state.showOtpSection = action.payload;
    },
  },
});

export const { setSubmitButtonDisabled, setShowOtpSection } =
  buttonSlice.actions;

export const isSubmitButtonDisabled = (state: any) =>
  state.button.submitButtonDisabled;

export const isOtpSectionVisible = (state: {
  button: { showOtpSection: any };
}) => state.button.showOtpSection;
export default buttonSlice.reducer;
