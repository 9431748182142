import React from 'react';

type FuelGaugeProps = {
  level: number;
};

const FuelGauge: React.FC<FuelGaugeProps> = ({ level }) => {
  const boxColors = [
    'bg-red-500',
    'bg-orange-500',
    'bg-yellow-500',
    'bg-yellow-800',
    'bg-green-500',
  ];

  return (
    <div className={'flex flex-col gap-2'}>
      <h4>Fuel Gauge</h4>
      <div className="flex justify-between gap-2">
        {boxColors.map((color, index) => (
          <div
            key={index}
            className={`w-full h-12 rounded flex items-center justify-center text-white font-semibold ${index < level ? color : 'bg-gray-400'}`}
          >
            {index + 1}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FuelGauge;
